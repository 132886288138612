body {
  font-family: simply;
  color: white;
}

html {
  overflow-x: hidden;
}

iframe {
  width: 100%;
  height: 35vh;
}

.bold {
  font-size: 110%;
}

.nav-wrapper {
  background-image: url("../assets/Group48.png");
  background-size: calc(100% + 25%) 125%;
  background-position: 55% 25%;
  background-repeat: no-repeat;
}

.nav-bar {
  // position: fixed;
  width: 100%;
  padding-top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  z-index: 100;
  position: relative;

  // background: transparent linear-gradient(90deg, #4ceabf 0%, #bf30c8 100%) 0% 0%
  //   no-repeat padding-box;
  // box-shadow: 0px 30px 30px #f90808;
  // opacity: 1;
  // backdrop-filter: blur(12px);
  // -webkit-backdrop-filter: blur(12px);
  .items {
    display: flex;
    font-size: 140%;

    > div {
      margin: 10px 20px;
      cursor: pointer;
    }
  }

  > img {
    position: absolute;
    left: -3vw;
    right: -3vw;
    min-height: 153px;
    top: -1vw;
    z-index: -1;
  }

  .icons {
    float: right;
    position: absolute;
    right: 8vw;

    img {
      margin: 12px;
      cursor: pointer;
    }

    img:hover {
      filter: grayscale(0.7);
    }

    img:active {
      position: relative;
      top: 2px;
    }
  }
}

.home {
  // background-image: url("../assets/BG.png");
  background-size: 100% 100%;
  padding: 70px 50px;
  text-align: left;
  line-height: 1.5;
  padding-bottom: 150px;

  .heading {
    margin-bottom: 10px;
    font-size: 650%;
    margin-top: 0;
  }

  .description {
    width: 80%;
    font-family: Lato;
    font-weight: bold;
    font-style: italic;
    line-height: 1.5;
    letter-spacing: 1.3px;
    color: rgba(255, 255, 255, 0.767);
    font-size: 110%;

    > div {
      margin-bottom: 20px;
    }
  }

  > div:first-child {
    width: 55%;
    padding: 20px 5vw;
  }

  > div:nth-child(2) {
    width: 35vw;
    position: relative;
    height: 35vw;

    > img {
      width: 100%;
      border: 1px solid rgba(255, 255, 255, 0.74);
      border-radius: 8%;
    }

    > img:nth-child(2) {
      position: absolute;
      width: 130%;

      left: -15%;
      top: -25%;
      filter: blur(30px);
      z-index: -1;
    }

    > div {
      width: 10vw;
      height: 10vw;
      left: 18%;
      top: 17%;
      position: absolute;
      overflow: hidden;

      > img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 10;
        filter: brightness(1.5);
        opacity: 0.5;
      }

      > div {
        display: inline-block;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 12;
        height: 100%;
        border-radius: 100px;
        color: red;
        // filter: blur(20px) brightness(15);
        backdrop-filter: blur(50px);
        // transform: scale(0.9);
        clip-path: circle(100% at center);
      }
    }
  }
}

.heading {
  font-family: Sniglet;
  font-weight: 600;
}

.blobs {
  .connect {
    display: inline-flex !important;
  }

  .blob-blocks {
    img {
      width: 20vw;
      max-width: 250px;
      margin: 2vw;
      border: 2px solid white;
    }
  }
}

.babies {
  background: #bf30c8;
  padding: 2px; // padding-bottom: 0;

  @keyframes animateLeft {
    0% {
      margin-left: calc(0);
    }
    100% {
      margin-left: calc(-70% + 100vw);
    }
  }

  @keyframes animateRight {
    0% {
      margin-left: calc(0);
    }
    100% {
      margin-left: calc(-70% + 100vw);
    }
  }

  .animateLeft {
    -webkit-animation: animateLeft 30s linear infinite;
    -moz-animation: animateLeft 30s linear infinite;
    animation: animateLeft 30s linear infinite;
    animation-direction: alternate;
  }

  .animateRight {
    -webkit-animation: animateRight 30s linear infinite;
    -moz-animation: animateRight 30s linear infinite;
    animation: animateRight 30s linear infinite;
    animation-direction: alternate-reverse;
  }

  .baby-block::-webkit-scrollbar {
    display: none;
  }

  .baby-block {
    scrollbar-width: none;
    // display: flex;
    // align-items: center;
    // justify-content: space-evenly;
    overflow: auto;
    display: flex;

    > div {
      display: flex;

      > div {
        display: flex;
      }
    }

    // }

    img {
      width: 20vw;
      height: 20vw;
      margin: 2px;
    }
  }
}

.flex {
  display: flex;
}

.flex-x {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-x-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-y {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sale {
  button {
    background: #f243ff;
    color: white;
    font-size: 200%;
    font-family: Sniglet;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: #4ceabf;
    }

  }

  background-image: url("../assets/Path7.png");
  margin-top: -150px;
  background-size: 100% 130%;
  min-height: 200px;
  padding-bottom: 70px;

  .detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4ceabf;

    > span:last-child {
      font-family: Sniglet;
      font-size: 150%;
      font-weight: bold;

      img {
        margin-right: 12px;
        width: 12px;
      }
    }
  }

  .counter {
    max-width: 240px;
    background: url("../assets/Group\ 19.svg");
    background-size: 100% 100%;
    // min-height: 30px;
    padding: 2px 15px;
    margin-bottom: 10px;

    img {
      width: 13%;
      cursor: pointer;
    }

    img:active {
      transform: scale(1.1);
    }

    input {
      background: transparent;
      outline: none;
      border: none;
      text-align: center;
      font-size: 240%;
      font-weight: bold;
      color: #bf30c8;
      font-family: Sniglet;
      width: 60%;
    }
  }

  .block {
    background-image: url("../assets/Subtraction3.svg");
    background-size: 100% 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    z-index: 100;

    > div:first-child {
      padding-bottom: 4%;
      padding-left: 7%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(86%);

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 60%;
      }

      > img {
        width: 20%;
      }
    }

    // min-height: 23vw;
    width: 70vw;
  }
}

.blobs {
  background: #41bbac;
  // padding: 100px 15vw;
  font-size: 115%;
  padding: 120px 10px;
  padding-bottom: 50px;

  .heading {
    font-size: 250%;
    color: #4ceabf;
    margin-bottom: 40px;
  }

  .connect {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.connect:hover {
  // filter: hue-rotate(45deg);
  filter: drop-shadow(2px 4px 6px black);
}

.connect:active {
  position: relative;
  top: 2px;
}

.sale-block {
  p {
    font-size: 2.5rem !important;
    margin: 0.5em 0;
  }

  font-family: Sniglet;
  font-weight: bold;
  flex-direction: column;
  padding: 40px;
  margin: 20px;
  width: 50vw !important;
  border-radius: 20px;
  // min-height: 10vw !important;
  box-shadow: 0px 20px 30px #00000069;
  background: transparent linear-gradient(to bottom, #4ce8bec5 0%, #be30c8b9 100%) 0% !important;

  > div:first-child {
    color: #4ceabf;
    font-size: 375%;
    padding: 0 !important;
    // padding-bottom: 20px !important;
    width: initial !important;
  }

  > div:nth-child(2) {
    color: white;
    font-size: 300%;
    background-image: url("../assets/Group 19.png");
    background-size: 100% 100%;
    padding: 10px 20px;
  }
}

.connect {
  background-image: url("../assets/ConnectRectangle.svg");
  // background-size: 105% 105%;
  // padding: 4vw 5vw;
  // min-width: 10vw;

  background-size: 110% 106%;
  /* padding: 4vw 5vw; */
  min-width: 15vw;
  background-position: 51%;
  min-height: 9vw;
  cursor: pointer;
  font-family: Sniglet;
  font-weight: 600;
  font-size: 2vw;
}

.block-wrapper {
  position: relative;

  .heading {
    font-size: 4vw;
    color: hsla(164, 79%, 61%, 1);
  }
}

.team {
  padding: 80px 5vw;
  width: 75vw;

  .team-wrapper {
    width: 42%;
    margin: 2%;

    // background: transparent linear-gradient(180deg, #4ce8bf 0%, #9066bf 100%) 0%
    //   0% no-repeat padding-box;
    // box-shadow: 0px 1px 15px #00000059;
    // border-radius: 15px;
  }

  .team-wrapper:nth-child(odd) {
    // background: transparent linear-gradient(180deg, #9066bf 0%, #4ce8bf 100%) 0%
    //   0% no-repeat padding-box;
  }

  .team-wrapper:nth-child(odd) {
    .team-block {
      background: transparent linear-gradient(180deg, #9066bf 0%, #4ce8bf 100%) 0% 0% no-repeat padding-box;
    }
  }

  .team-block {
    display: flex;
    position: relative;
    // margin: 15px;
    background: transparent linear-gradient(180deg, #4ce8bf 0%, #9066bf 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 15px #00000059;
    border-radius: 15px;
    padding: 10px;

    .description {
      width: calc(100% - 20px - 40px);
      position: absolute;
      left: 10px;
      border-radius: 0 0 10px 10px;
      // min-height: 30%;
      display: flex;
      align-items: center;
      text-align: justify;
      justify-content: center;
      font-family: Lato;
      font-weight: normal;
      font-style: italic;
      color: rgba(255, 255, 255, 0.767);
      letter-spacing: 1.2px;
      font-size: 90%;
      padding: 10px 20px;
      bottom: 10px;
      background: transparent linear-gradient(180deg, #4ce8bf 0%, #9066bf 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 15px #00000059;
    }

    > div:last-child {
      position: absolute;
      text-align: left;
      z-index: -1;
      left: 112%;
      top: 0%;
      transform: rotate(90deg);
      transform-origin: top left;
      height: 20%;
      width: 70%;
      padding-left: 25px;
      font-size: Min(45px, 3vw);

    }

    @media (min-width: 1000px) {
      > div:last-child {
        font-size: 3vw;
        position: absolute;
        text-align: left;
        z-index: -1;
        top: 0%;
        left: 112%;
        transform: rotate(90deg);
        transform-origin: top left;
        height: 20%;
        width: 70%;
        padding-left: 25px;
        font-size: Min(45px, 3vw);
      }

    }

    img,
    iframe {
      width: 100%;
      border: none;
      border-radius: 15px;
    }
  }

  .heading {
    font-size: 250%;
    margin-left: 22px;
    text-align: left;
  }
}

.faq {
  background: #41bbac;
  padding: 100px 15vw;
  text-align: left;
  font-size: 115%;
  padding-bottom: 150px;

  .heading {
    font-size: 250%;
    color: #4ceabf;
  }

  .faq-block {
    letter-spacing: 1.5px;

    > div:first-child {
      font-weight: bold;
      margin-top: 30px;
      font-size: 120%;
    }

    > div:last-child {
      margin-top: 10px;
      opacity: 0.8;
      font-family: Lato;

      a:link {
        color: white;
      }

      a:hover {
        font-weight: bolder;
      }

      a:visited {
        color: white;
      }

    }
  }
}

.footer {
  padding: 20px 15vw;
  padding-top: 40px;
  color: #7cdcc2;

  > div:nth-child(2) {
    img {
      width: 200px;
    }

    > div {
      margin-top: -40px;
    }
  }

  > div:nth-child(3) {
    img {
      width: 35px;
      margin: 15px;
      cursor: pointer;
    }

    img:nth-child(3) {
      width: 40px;
      margin: 15px;
      cursor: pointer;
      padding-bottom: 3px;
    }

    img:hover {
      filter: grayscale(0.7);
    }

    img:active {
      position: relative;
      top: 2px;
    }
  }

  .heading {
    font-size: 300%;
    color: #4ceabf;
  }

  background: transparent linear-gradient(180deg, #41bbac 30%, #bf30c8 180%) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #4ceabf;
}

.mobile {
  display: none;
}

body {
  overflow-x: hidden;
}

.connect {
  color: rgba(255, 255, 255, 0.308);
}

@media only screen and (max-width: 1200px) {
  body {
    .icons {
      position: relative !important;
      right: inherit !important;

      img {
        margin: 6px 10px !important;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .sale-block {
    p {
      font-size: 2em !important;
    }

    padding: 20px !important;
    width: 60vw !important;

    > div:last-child {
      padding: 10px;
    }
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
    margin-top: 15px;
  }
  body {
    overflow-x: hidden;
  }
  // .team-block {
  //   img {
  //     width: 300px !important;
  //   }
  // }
  .team {
    // > div:nth-child(2) {
    //   display: block;
    // }
  }
  .faq {
    padding: 120px 25px;
  }
  .home {
    flex-direction: column-reverse;

    .heading {
      font-size: 400%;
    }

    > div:nth-child(2) {
      width: 80%;
    }
  }
  .nav-bar {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nav-wrapper {
    background-size: calc(100% + 25%) 125%;
  }

  iframe {
    height: 40vh;
  }

  body {
    font-size: 90%;

    .items {
      font-size: 80%;

      > div {
        margin: 8px !important;
      }
    }

    .icons {
      position: relative !important;
      right: inherit !important;

      img {
        margin: 6px 10px !important;
      }
    }
  }
  .sale {
    padding: 70px 20px;

    .heading {
      left: 25vw;
      bottom: 16vw;
      font-size: 200%;
    }

    .block {
      width: 140vw;
      font-size: 100%;
      background-image: initial;
    }
  }
  .sale .block > div:first-child > img {
    width: 30%;
  }
  .sale .counter {
    max-width: 160px !important;
  }
  .connect {
    min-height: 22vw;
    min-width: 30vw;
    font-size: 150% !important;
  }
  .sale .block > div:first-child {
    flex-direction: column;

    > div {
      padding: 20px;
      margin: 20px 0;
      width: 70%;
      border-radius: 20px;
      box-shadow: 0px 20px 30px #00000069;
      background: transparent linear-gradient(to bottom, #4ce8bec5 0%, #be30c8b9 100%) 0%;
    }
  }
  .home {
    padding: 60px 20px;
    padding-bottom: 130px;

    .heading {
      font-size: 365%;
    }

    .description {
      width: 100%;
      font-size: 97%;
    }

    > div:nth-child(2) {
      height: auto;
    }

    > div:first-child {
      width: initial;
    }
  }
  .team .team-block > div:last-child {
    font-size: 6.3vw;
  }
  .team .heading {
    margin-left: 50px;
  }
  .team-wrapper {
    width: 75% !important;
    margin: 30px 0 !important;
  }
  .team {
    width: 90vw;
    padding: 30px 3vw;

    > div:nth-child(2) {
      flex-direction: column;
    }
  }

  .babies .baby-block img {
    width: 25vw;
    height: 25vw;
  }

  .footer > div:nth-child(2) img {
    width: 30vw;
  }
  .footer .heading {
    font-size: 170%;
    white-space: nowrap;
    margin-top: -30px !important;
  }
  .footer {
    padding: 50px 30px;
  }
  .footer > div:nth-child(3) img {
    margin: 3px;
    width: 30px;
  }
  .footer > div:nth-child(3) img:nth-child(3) {
    margin: 3px;
    width: 33px;
  }
  .footer > div:nth-child(3) {
    white-space: nowrap;
  }
}
